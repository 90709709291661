.login_page {background:#f5f5f5; height:100vh; min-height:580px; flex-direction:column; display:flex; justify-content:center; align-items:center; position:relative;}
.login_panel {background:#ffffff; /*min-height:300px;*/ border-radius:var(--main-corner-radius); user-select:none; box-shadow:1px 1px 30px -1px rgba(0,0,0, 0.08);}
.login_panel_inner_wrapper {padding:22px; display:flex;}

.login_block {width:270px; max-width:270px; display:flex; flex-direction:column;}
.logo_chemwatch_beehive {text-align:center; padding:0 0 24px 0;}
.logo_chemwatch_beehive svg {width:225px; height:55px;}
.region {text-align:center; margin:32px 0 16px 0; color:var(--contrast-tx);}
.login_block .link {color:var(--main-accent-color); border-bottom:1px solid transparent; transition:border var(--main-transition);}
.login_block .link:hover {text-decoration:none; border-bottom:1px solid var(--main-accent-color);}

.forgot_password {display:flex; justify-content:space-between; padding:0 1px;}
.sign_up {text-align:center; padding:8px 0 0 0;}

/* Information Block */
/*
.info_block {width:440px; margin:0 0 0 22px; border-left:1px solid #ebebeb;}
.info_block h1 {font-size:22px; font-weight:normal; padding:0 0 4px 16px; color:var(--contrast-tx2); display:flex; justify-content:space-between; align-items:center;}
.info_block h1 span {font-size:16px; color:#a2a6aa;}
.info_block .inner {height:400px; font-size:12px; line-height:18px; padding:0 0 0 18px; color:var(--contrast-tx);}
.info_block .scroll > div:nth-child(2) {height:100% !important; right:-8px !important;}
.info_block h2 {font-size:12px; font-weight:bold; line-height:16px; padding:4px 0 0 0; margin:4px 0; color:var(--contrast-tx);}
.info_block p {padding:0 0 8px 0;}

.info_block ul {padding:0 0 8px 16px;}
.info_block ul li {position:relative;}
.info_block ul li:before {content:""; background:#a2a6aa; width:4px; height:4px; border-radius:4px; position:absolute; left:-10px; top:7px;}

.info_block a {color:var(--main-accent-color); border-bottom:1px solid var(--main-accent-color); text-decoration:none; transition:border var(--main-transition);}
.info_block a:hover {border:transparent; text-decoration:none;}

.info_block table {width:100%; margin:0 0 14px 0;}
.info_block table th, .info_block table td {padding:2px 8px; border:1px solid var(--border-color-m2); text-align:left;}
.info_block table th {background:#f2f2f2; color:var(--contrast-tx2); border:1px solid var(--border-color-m1); border-top:0}
.info_block table th:first-child, .info_block table td:first-child {border-left:0;}
.info_block table th:last-child, .info_block table td:last-child {border-right:0;}
*/

/* Footer */
.footer {background:#ebebeb; width:100%; padding:4px 0; display:flex; align-items:center; justify-content:space-between; position:absolute; bottom:0; left:0; border-top:1px solid #dddddd;}
.footer a {filter:grayscale(1); opacity:0.5; transition:var(--main-transition); display:inline-block; position:relative;}
.footer a:hover {filter:grayscale(0); opacity:1;}

.social_media {padding:0 36px 0 0; display:flex; align-items:center; white-space:nowrap; flex-wrap:wrap;}
.social_media li {padding:0 0 0 20px;}
.social_media svg {height:30px;}
.twitter {top:-1px;}

.external_logos {display:flex; align-items:center; flex-wrap:wrap;}
.external_logos a {margin:4px 20px 4px 0;}
.external_logos img {height:36px;}
.iteca {top:2px;}
.iteca img {height:34px;}
.pumascan {}
.jas_anz {top:1px;}
.jas_anz img {height:42px;}
.nfpa img {height:38px;}
.iso {}
.nrt {top:2px;}
.govea {cursor:default;}
.govea img {height:46px;}

@media (max-width:1000px) {
    .login_panel {margin:auto;}
    .footer {position:unset;}
}
