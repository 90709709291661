html, body {height:100%;}
*, html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {margin:0; padding:0;}
table {border-collapse:collapse; border-spacing:0;}
body {font-family:Arial, Helvetica, sans-serif; font-size:13px; font-weight:normal; width:100%; height:100%;}
img {border:0; display:block;}
input, select, textarea {font-family:Arial, Helvetica, sans-serif; font-size:13px; outline:none;}
a {outline:none; text-decoration:none;}
a:hover {text-decoration:underline;}
li {list-style-type:none;}
input::-ms-clear {display:none;}
input::selection {background:var(--main-accent-color); color:#fff;}
.dark-skin input::selection {color:#232328;}

body {
    --base-font-family:Arial, Helvetica, sans-serif;
    --main-accent-color:#2371da;
    --main-accent-color-more-dark:#266ccb;
    --main-accent-color-more_light:#5b8ae1;
    --green-accent-color:#38a21f;
    --green-accent-transparent-bg:rgba(56,162,31, 0.1);
    --blue-accent-color:#4695ff;
    --yellow-accent-color:#ffe112;
    --orange-accent-color:#ff983d;
    --red-accent-color:#e75353/*#e85a5a*/;
    --red-accent-transparent-bg:rgba(231,83,83, 0.1);
    --main-corner-radius:3px;
    --main-box-shadow:1px 1px 2px -1px rgba(0,0,0, 0.2);
    --popup-context-shadow:0 3px 18px -7px rgba(0,0,0, 0.5);
    --main-tx:rgba(44, 51, 56, 0.8);
    --contrast-tx:rgba(52, 55, 59, 0.9);
    --contrast-tx2:rgba(36, 37, 39, 0.9);
    --main-more-light:rgba(67, 73, 78, 0.63);
    --main-icon-color:#7f838a;
    --main-panels-bg:#fff;
    --bg-acc:#f9f9f9;
    --bg-acc2:#f6faff;
    --bg-acc-shadow:#ededed;
    --placeholder-color:#abaeb2;
    --border-color-m1:rgba(0, 0, 0, 0.12);
    --border-color-m2:rgba(0, 0, 0, 0.09);
    --border-c1:rgba(152, 158, 166, 0.7);
    --top-control-line:34px;
    --main-transition:.3s cubic-bezier(.645,.045,.355,1);
    --base-line-height: 1.5715;
    --grid-cell-accent:#ecf6ff;
    --grid-elements-hover-state:#fff;
    --popups-bg:#fff;
    --popups-inner-hover:var(--bg-acc);
    --popups-group-title:#f4f9ff;
    --dragging-bg:rgba(234,243,255, 0.5);
    --custom-button-in-panels:#fff;
    --custom-button-in-panels-shadow:1px 1px 3px -1px rgba(0, 0, 0, 0.1);
}

/*--- Main Frame---*/
#root {height:100%;}
.maincontainer {background:#fff; width:100%; height:100%; min-height:540px; display:flex; flex-direction:column; color:var(--main-tx); user-select:none;}
.maincontainer header {width:100%; height:100px; min-height:100px; display:flex;}
.beehive-logo-text {fill:#7f838a;}

.vendor-autocomplete .ant-select-selector {padding:0 8px;}
.vendor-autocomplete .ant-select-selection-search {left:8px; right:8px;}

.maincontainer footer {background:#f7f7f7; width:100%; height:40px; min-height:40px; padding:0 12px; margin:12px 0 0 0; border-top:1px solid rgba(255,255,255, 0.5); --awards-filter:grayscale(1); --awards-filter-hover:grayscale(0);}
.mainspace {width:100%; height:100vh;}

/* Outback Main Grid */
.outback-main-grid {height:100%; margin:0 16%; overflow:hidden; display:flex; flex-direction:column;}
.outback-main-grid-inner {flex:auto; overflow:auto;}
.outback-main-grid .ant-table-wrapper .ant-table-thead {display:none;}
.outback-main-grid .ant-table-wrapper .ant-table-container > .ant-table-body {overflow:unset !important; height:100%; max-height:100% !important;}
.outback-main-grid .ant-table-wrapper .ant-table-container > .ant-table-body table {table-layout:auto !important;}
@media (max-width:1030px) {.outback-main-grid {margin:0 14px;}}
@media only screen and (max-width:576px) {.ant-pagination-options {display:inline-block;}}
@media only screen and (max-width:330px) {.ant-pagination-options {display:none;}}
.grid-custom-pagination {width:100%; padding:12px 0 0 0; display:flex; align-items:center; justify-content:end;}
.grid-custom-pagination .ant-pagination-total-text {font-style:italic; color:var(--main-more-light);}
.outback-main-grid .grid-buttons {display:flex; opacity:0; transition:opacity var(--main-transition);}
.outback-main-grid .ant-table-cell.ant-table-cell-row-hover .grid-buttons {opacity:1;}
.outback-main-grid .grid-buttons > button {white-space:nowrap; margin:0 0 0 8px;}
button.icon-button svg {height:13px; margin:0 10px 0 0; color:var(--main-icon-color); position:relative; top:2px; transition:color var(--main-transition);}
button.icon-button:hover svg {color:var(--main-accent-color);}
button.icon-button.view-grid-button svg {height:17px; margin:0 8px 0 0; top:3px}
button.icon-button.download-grid-button svg {}
button.icon-button.share-grid-button svg {}
button.icon-button.ant-btn-primary svg {color:#fff;}

/*--------- ANT main style correction ---------*/
/*--- ANT Tabs ---*/
.ant-tabs {color:var(--main-tx);}
.ant-tabs-nav .ant-tabs-tab {padding:8px 30px; line-height:1.5; margin:0; font-weight:normal; position:relative;}
.ant-tabs-nav .ant-tabs-tab span {position:relative; z-index:1;}
.ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs-tab-btn:focus, .ant-tabs-tab-btn:active {color:var(--main-accent-color);}
.ant-tabs-nav .ant-tabs-tab:after {content:""; background:var(--bg-acc); width:100%; position:absolute; bottom:0; left:0; height:0; transition:height .3s cubic-bezier(.645,.045,.355,1) 0.1s;}
.ant-tabs-nav .ant-tabs-tab-active:after {height:100%;}
.ant-tabs.ant-tabs-vertical .ant-tabs-tab:after {width:0; height:100%; transition:width .3s cubic-bezier(.645,.045,.355,1) 0.1s;}
.ant-tabs.ant-tabs-vertical .ant-tabs-tab-active:after {width:100%; right:0;}

/*--- Custom Switcher Underlined ---*/
.switcher-underlined .ant-tabs-tab {background:none; padding:0 4px 1px 4px; margin:0 8px 0 0; border-bottom:1px dashed transparent;}
.switcher-underlined .ant-tabs-tab:after {display:none;}
.switcher-underlined .ant-tabs-nav:before {border:0 !important;}
.switcher-underlined .ant-tabs-nav .ant-tabs-ink-bar {height:1px;}
/*--- in tab-pane block ---*/
.switcher-underlined.in-tab-pane .ant-tabs-bar {padding:14px 0;}
.switcher-underlined.in-tab-pane .ant-tabs-ink-bar {height:1px;}

/*--- Checkbox ---*/
.ant-checkbox-wrapper {color:var(--contrast-tx);}
.ant-checkbox .ant-checkbox-inner {background-color:#fff; width:14px; height:14px; border:1px solid var(--border-c1);}
.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {border-color:var(--main-accent-color-more_light);}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {background:var(--main-accent-color);}
.ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner:after {border-color:var(--main-accent-color); width:5px; height:9px; top:5px; left:2px;}
.ant-checkbox-checked:after {border:1px solid var(--border-c1);}
.ant-checkbox-disabled {opacity:0.6;}
.ant-checkbox-disabled + span {color:var(--main-more-light);}

/*--- Radio ---*/
.ant-radio-wrapper {color:var(--contrast-tx);}
.ant-radio .ant-radio-inner {background-color:#fff; width:14px; height:14px; border:1px solid var(--border-c1);}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {border-color:var(--main-accent-color-more_light);}
.ant-radio-input:focus + .ant-radio-inner {box-shadow:none;}
.ant-radio .ant-radio-inner:after {transform: scale(0.4);}

/*--- Switch [small] ---*/
.ant-switch {background-color:rgba(0,0,0, 0.2);}
.ant-switch-checked {background-color:var(--main-accent-color);}
.ant-switch-checked:focus {box-shadow:none;}
.ant-switch-small {min-width:24px; height:14px; line-height:14px;}
.ant-switch-small.ant-switch-checked .ant-switch-handle {left:calc(100% - 12px);}
.ant-switch-small .ant-switch-handle {width:10px; height:10px;}

/*--- Input ---*/
.ant-input, .ant-picker {border:1px solid var(--border-color-m1); color:var(--contrast-tx);}
.ant-input:hover, .ant-input:focus, .ant-input-focused, .ant-picker:hover, .ant-picker-focused {box-shadow:none; border-color:var(--main-accent-color-more_light);}

/*--- Input, Picker (small size) ---*/
.ant-input.ant-input-sm, .ant-picker-small {padding:2px 8px;}
.ant-picker-small {height:28px;}

/*--- Date Picker ---*/
.ant-picker-input > input {color:var(--contrast-tx); line-height:26px;}
.ant-picker-suffix, .ant-picker-clear svg {color:var(--main-icon-color);}
.ant-picker-clear:hover svg {fill:var(--main-accent-color);}

/*--- Input [number]---*/
.ant-input-number {border:1px solid var(--border-color-m1); color:var(--contrast-tx);}
.ant-input-number:hover, .ant-input-number:focus, .ant-input-number-focused {box-shadow:none; border-color:var(--main-accent-color-more_light);}
.ant-input-number-handler-wrap {background:none;}
.ant-input-number-handler {border-color:var(--border-color-m2);}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {font-size:9px; color:var(--main-icon-color);}
.ant-input-number-handler-wrap .ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler:hover .ant-input-number-handler-down-inner {color:var(--main-accent-color);}
.ant-input-number-handler-wrap .ant-input-number-handler:active {background:var(--grid-cell-accent);}

/*--- Dropdown / Selector ---*/
.ant-select:not(.ant-select-customize-input) .ant-select-selector {border:1px solid var(--border-color-m1);}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {border-color:var(--main-accent-color-more_light);}
.ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {border-color:var(--main-accent-color-more_light); box-shadow:none;}
/*--- Search text color in ANT selectors ---*/
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {color:var(--contrast-tx);}

/*--- Custom Dropdown ---*/
.custom-simple-dropdown.ant-select {max-width:100%; height:22px;}
.custom-simple-dropdown.ant-select .ant-select-selector {height:22px; padding:0 8px;}
.custom-simple-dropdown:not(.in-form).ant-select .ant-select-selector, .custom-simple-dropdown:not(.in-form).ant-select.ant-select-focused .ant-select-selector {box-shadow:none; border:0;}
.custom-simple-dropdown .ant-select-selector .ant-select-selection-item {line-height:22px; color:var(--contrast-tx); opacity:1;}
.custom-simple-dropdown .ant-select-arrow {width:10px; height:10px; margin-top:-8px; right:8px; color:var(--main-more-light);}
.custom-simple-dropdown .ant-select-arrow svg {transform:rotate(0); transition:0.3s;}
.custom-simple-dropdown.ant-select-open .ant-select-arrow svg {transform:rotate(180deg);}
/* hidden */
.ant-select-selector .ant-select-selection-search-input {height:100% !important;}
/* Folder Type Selector */
.custom-simple-dropdown.ant-select.folder-type-selector .ant-select-selection-item, .ant-select-dropdown.folder-type-selector .ant-select-item-option-content {display:flex; align-items:center;}
.custom-simple-dropdown.ant-select.folder-type-selector .ant-select-selection-item > span:first-child, .ant-select-dropdown.folder-type-selector .ant-select-item-option-content > span:first-child {max-width:18px; margin:0 8px 0 0;}

/*--- Dropdown with search ---*/
.custom-simple-dropdown.ant-select.ant-select-show-search .ant-select-arrow svg {transform:rotate(0); opacity:0; transition:opacity var(--main-transition);}
.custom-simple-dropdown.ant-select.ant-select-show-search .ant-select-arrow .ant-select-suffix:after {content:""; width:10px; height:10px; position:absolute; left:0; top:4px;}
.custom-simple-dropdown.ant-select.ant-select-show-search .ant-select-arrow .ant-select-suffix:after {background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.839 4.657' %3E%3Cpath d='M4.7,4.864A.5.5,0,0,1,4.322,4.7L.912,1.048A.5.5,0,0,1,1.643.365L4.7,3.64,7.751.365a.5.5,0,0,1,.732.682L5.072,4.7a.5.5,0,0,1-.366.159Z' fill='rgba(67, 73, 78, 0.63)' transform='translate(-0.777 -0.207)'%3E%3C/path%3E%3C/svg%3E"); background-repeat:no-repeat; opacity:1; transition:opacity var(--main-transition);}
.custom-simple-dropdown.ant-select.ant-select-show-search.ant-select-open .ant-select-arrow svg {opacity:1;}
.custom-simple-dropdown.ant-select.ant-select-show-search.ant-select-open .ant-select-arrow .ant-select-suffix:after {opacity:0;}
.custom-simple-dropdown.ant-select.ant-select-show-search .ant-select-arrow .anticon-search {padding:2px 0 0 0;}

/*--- Dropdown for Main Toolbar ---*/
.custom-simple-dropdown.for-main-toolbar {overflow:hidden;}
.custom-simple-dropdown.for-main-toolbar.ant-select .ant-select-selector, .custom-simple-dropdown.for-main-toolbar.ant-select.ant-select-focused .ant-select-selector {border-radius:20px; box-shadow: 1px 1px 0 rgba(0,0,0, 0.1) inset;}
.custom-simple-dropdown.for-main-toolbar.ant-select .ant-select-selector .ant-select-selection-item {font-weight:bold;}
.custom-simple-dropdown.for-main-toolbar.ant-select .ant-select-arrow {right:10px;}

/*--- Custom Standart Dropdown (small version) ---*/
.custom-simple-dropdown.small.ant-select {font-size:12px;}
.custom-simple-dropdown.small .ant-select-arrow {width:8px; height:8px; right:8px;}
.custom-simple-dropdown.small.ant-select.ant-select-show-search .ant-select-arrow .ant-select-suffix:after {width:8px; height:8px; top:5px;}

/*--- Dropdown Styles ---*/
.custom-simple-dropdown.transparent .ant-select-selector {background:none; box-shadow:none; border:0;}
.custom-simple-dropdown.transparent .ant-select-selection-item {color:var(--contrast-tx2);}

/*--- Dropdown List style ---*/
.ant-select-dropdown .ant-select-item {min-height:26px; padding:2px 10px; color:var(--main-tx);}
.ant-select-dropdown .ant-select-item-option-active {background-color:var(--bg-acc);}
.ant-select-dropdown .ant-select-item-option-selected {background-color:var(--bg-acc); color:var(--contrast-tx); cursor:default;}
.ant-select-dropdown .ant-select-item-group {background:var(--popups-group-title); font-style:italic; color:var(--contrast-tx); opacity:1; border-bottom:1px solid var(--border-color-m2);}
.ant-select-dropdown .ant-select-item-option-grouped .ant-select-item-option-content {padding-left: 10px;}
/* Dropdown List [Scroll] style */
.ant-select-dropdown .rc-virtual-list-scrollbar {background:rgba(0, 0, 0, 0.02);}
.ant-select-dropdown .rc-virtual-list-scrollbar-thumb {background: rgba(166, 168, 173, 0.7) !important; width:7px !important; left:1px !important;}

/*--- Dropdown List Menu style ---*/
.simple-menu-w-markers {color:var(--main-icon-color);}
.simple-menu-w-markers .ant-dropdown-menu-item {padding:5px 24px 5px 12px; display:flex; align-items:center; overflow:hidden;}
.simple-menu-w-markers .ant-dropdown-menu-item svg {width:12px; height:12px; margin:0 0 0 auto; color:var(--main-accent-color); transition:right var(--main-transition); position:relative; right:-40px;}
.simple-menu-w-markers .ant-dropdown-menu-item.selected {background:var(--bg-acc); color:var(--main-accent-color);}
.simple-menu-w-markers .ant-dropdown-menu-item.selected svg {right:-12px;}

/*--- Dropdown List Menu style ---*/
.menu-w-edit-delete {width:260px !important; padding:5px;}
.menu-w-edit-delete {color:var(--main-icon-color);}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item {padding:5px 12px;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item.ant-select-item-group {padding:2px 12px;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item span.name {max-width:160px; display:block; overflow:hidden; text-overflow:ellipsis;}
.menu-w-edit-delete .small-custom-button {position:absolute; top:8px; right:30px; opacity:0; z-index:1; transition:opacity var(--main-transition);}
.menu-w-edit-delete .small-custom-button svg {width:16px; height:16px;}
.menu-w-edit-delete .small-custom-button + .small-custom-button {right:8px;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item:hover .small-custom-button {opacity:1;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item .ant-select-item-option-content > svg {width:12px; height:12px; color:var(--main-accent-color); transition:opacity var(--main-transition); position:absolute;  top:10px; right:10px; opacity:0;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {background:var(--bg-acc); color:var(--main-accent-color);}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content > svg {opacity:1;}
.menu-w-edit-delete.ant-select-dropdown .ant-select-item:hover.ant-select-item-option-selected .ant-select-item-option-content > svg {opacity:0;}
/*--- Add Button in Dropdown ---*/
.add-button-in-dropdown {height:26px; display:flex; border-top:1px solid rgba(0,0,0, 0.1)}
.add-button-in-dropdown button {background:none; border:none; width:20px; height:20px; outline:none; margin:auto auto 0 auto; cursor:pointer; color:var(--main-icon-color); transition:color var(--main-transition);}
.add-button-in-dropdown button:hover {color:var(--main-accent-color);}
.add-button-in-dropdown button svg {width:16px; height:16px;}

/*--- Multiple Selector ---*/
.ant-select.ant-select-multiple {}
.ant-select-multiple {min-height:26px;}
.ant-select-multiple .ant-select-selector {padding:1px 4px; border:1px solid var(--border-color-m1);}
.ant-select-multiple .ant-select-selection-item {background:var(--bg-acc); height:22px; margin:2px 4px 2px 0; border:1px solid #efefef;}
.ant-select-multiple .ant-select-selection-item-content {color:var(--main-tx);}
.ant-select-multiple .ant-tag:hover {opacity:1;}
.ant-select-multiple .ant-select-selection-item-remove {line-height:22px; transition:color var(--main-transition);}
.ant-select-multiple .ant-select-selection-item-remove:hover {color:var(--main-accent-color);}
.ant-select-multiple .ant-select-selection-placeholder {color:var(--placeholder-color); opacity:1; transition:.5s cubic-bezier(.645,.045,.355,1);}
.ant-select-multiple .ant-select-selection-placeholder {width:auto; right:8px; left:8px; color:var(--placeholder-color); opacity:1; text-overflow:ellipsis;}
.ant-select-multiple.ant-select-focused .ant-select-selection-placeholder {color:transparent; text-indent:-1000px;}

/*--- ANT form ---*/
.ant-form-item-control-input {min-height:28px;}
.ant-form-item {margin:8px 0;}
.ant-form-item-label > label {height:28px; color:var(--main-tx);}
.ant-form-item :not(textarea).ant-input, .ant-input.in-form {height:28px; line-height:26px; padding:0 8px;}
.ant-form-item .ant-input-number-input, .ant-input-number-input.in-form {height:24px; padding:0 8px; color:var(--contrast-tx);}
.ant-form-item .ant-input-affix-wrapper {padding:0 8px;} /* for specific fields (ex. for password) */
.ant-form-item .ant-input-affix-wrapper > input.ant-input {height:26px;}
/*--- Pass Field ---*/
.ant-form-item .ant-input-affix-wrapper.ant-input-password {background:transparent; border:1px solid var(--border-color-m1);}
.ant-form-item .ant-input-affix-wrapper.ant-input-password > input.ant-input {background:transparent !important;}
.ant-form-item .ant-input-affix-wrapper.ant-input-password > input.ant-input {padding:0 8px 0 0;}
.ant-form-item .ant-input-affix-wrapper.ant-input-password .ant-input-password-icon {color:var(--main-icon-color);}
.ant-form-item .ant-input-affix-wrapper-focused, .ant-form-item .ant-input-affix-wrapper:focus {border:1px solid var(--main-accent-color-more_light) !important; box-shadow:none;}
/*--- selector Field ---*/
.custom-simple-dropdown.in-form.ant-select {height:28px; color:var(--contrast-tx2);}
.custom-simple-dropdown.in-form .ant-select-selector {height:28px; padding:0 8px; box-shadow:none;}
.custom-simple-dropdown.in-form .ant-select-selector .ant-select-selection-item {line-height:26px;}

.ant-form-item .ant-slider {margin:8px 0 0 0;}
.ant-form-item .ant-upload .ant-btn {padding:0 8px;}
.ant-form-item .ant-upload .ant-upload-list-item {margin:0 0 0 -4px;}
/*--- errors for field ---*/
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {background:rgba(229,97,97, 0.1); border-color:var(--red-accent-color);}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input::placeholder {color:#d1acac;}
.ant-form-item-explain-error, .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {color:var(--red-accent-color);}
/*--- form vertical orientation ---*/
.ant-form.ant-form-vertical .ant-form-item {margin:0 0 12px 0;}
.ant-form.ant-form-vertical .ant-form-item-label {padding:0 0 1px 0;}
.ant-form.ant-form-vertical.checkbox-list-form .ant-form-item {margin:0;}

/*--- Modal Window ---*/
.ant-modal {padding:0; color:var(--main-tx);}
.ant-modal-header {background:var(--bg-acc); padding:6px 10px;}
.ant-modal-title {font-size:13px; line-height:20px; font-weight:bold; color:var(--contrast-tx);}
.ant-modal-close-x {width:32px; height:32px; line-height:32px; color:var(--main-icon-color); transition:color var(--main-transition);}
.ant-modal-close-x:hover {color:var(--main-accent-color);}
.ant-modal-close-x svg {height:10px;}
.ant-modal-body {padding:10px 14px;}
.more-wide-inner-indents .ant-modal-body {padding:14px 20px;}
.ant-modal-footer {background:var(--bg-acc); padding:8px 10px;}
.ant-modal-footer button + button {margin-left:10px;}
.ant-modal-footer .ant-btn {height:26px; padding:0 12px;}
/* Expand Full Screen/Collapse Modal Window [Custom] */
/* button */
.custom-header-for-modal-window-with-expand-button {position:relative; padding:0 64px 0 0;}
.custom-expand-collapse-button-for-modal-window {background:none; width:24px; height:32px; line-height:32px; border:0; position:absolute; top:-6px; right:22px; cursor:pointer; outline:none;}
.custom-expand-collapse-button-for-modal-window:before, .custom-expand-collapse-button-for-modal-window:after {content:""; width:14px; height:12px; border:1px solid var(--main-icon-color); transition:border var(--main-transition); display:block; margin:auto; border-radius:2px;}
.custom-expand-collapse-button-for-modal-window:after {display:none;}
.custom-expand-collapse-button-for-modal-window:hover:before, .custom-expand-collapse-button-for-modal-window:hover:after {border:1px solid var(--main-accent-color);}
.custom-expand-collapse-button-for-modal-window.collapse:before {width:12px; height:10px; position:absolute; top:9px; left:9px;}
.custom-expand-collapse-button-for-modal-window.collapse:after {background:var(--bg-acc); width:12px; height:10px; margin:1px auto 0 auto; position:relative; display:block;}
/* window */
.ant-modal-wrap.expanded-modal-window .ant-modal {width:100% !important; max-width:100%; height:100%;}
.ant-modal-wrap.expanded-modal-window .ant-modal-content {height:100%; border-radius:0; box-shadow:none;}
.ant-modal-wrap.expanded-modal-window .ant-modal-body {height:calc(100% - 76px) !important;}
/* Window [Auto Width] */
.ant-modal.auto-width {width:auto !important;}

/* custom styles for modal window */
.title-for-modal-window {font-weight:bold; padding:2px 0 4px 0;}
.modal-window-additional-text {background:#fff7eb; padding:8px 16px; margin:0 -20px -14px -20px; border-top:1px solid #fff4e5; border-left:2px solid #5d5d5d;}

/*--- Modal Window [confirmation]---*/
.ant-modal-confirm .ant-modal-body {padding:22px;}
.ant-modal-confirm .ant-modal-confirm-btns {margin:12px 0 0 0; display:flex; flex-direction:row-reverse;}
.ant-modal-confirm .ant-modal-confirm-btns button {margin:0 0 0 8px;}
.ant-modal-confirm-body .ant-modal-confirm-title {color:var(--main-tx);}

/* Button */
.ant-btn {height:26px; padding:0 12px; text-shadow:none; box-shadow:1px 1px 4px -1px rgba(0,0,0, 0.1);}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not([disabled]) {cursor:pointer; color:var(--contrast-tx2);}
.ant-btn.ant-btn-primary:not(.ant-btn-dangerous):not([disabled]):hover {background:var(--main-accent-color); border-color:var(--main-accent-color);}
.ant-btn.ant-btn-dangerous {background-color:#ec4c4e; border-color:#ec4c4e; color:#fff;}
.ant-btn.ant-btn-dangerous:hover {background-color:#f55557; border-color:#f55557;}

/* Button (small version) */
.ant-btn-sm {height:24px; font-size:12px; line-height:1.5; padding:0 10px;}
.ant-btn-sm .anticon {font-size:11px; margin:0 8px 0 0;}

/*--- Table [ANT] ---*/
.ant-table {background:transparent; color:var(--main-tx);}
.ant-table.ant-table-small .ant-table-thead > tr > th {padding:4px 8px;}
.ant-table.ant-table-small .ant-table-tbody > tr > td {font-size:13px; line-height:20px; padding:6px 14px;}
.ant-table-body {overflow:auto !important;}
.ant-table .ant-table-tbody > tr > td {border-bottom:1px solid var(--border-color-m2);}
.ant-table .ant-table-thead > tr > th {background:var(--bg-acc); color:var(--contrast-tx);}
/* bordered */
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container {border:0;}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {border-right:0;}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {border-right:1px solid var(--border-color-m2);}
/* sorting */
.ant-table .ant-table-column-sorter-up, .ant-table .ant-table-column-sorter-down {font-size:10px; color:var(--main-icon-color); opacity:0.5;}
.ant-table .ant-table-column-sorter-up.active, .ant-table .ant-table-column-sorter-down.active {color:var(--main-accent-color); opacity:1;}
.ant-table td.ant-table-column-sort {background:var(--bg-acc);}

.ant-table .ant-table-cell.ant-table-selection-column {width:40px; min-width:40px;}
.ant-table .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {background:var(--bg-acc); color:var(--contrast-tx2);}
.ant-table .ant-table-tbody > tr.ant-table-row-selected > td {background:var(--bg-acc);}
/* dividers in header */
.ant-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {background-color:var(--border-color-m2);}
/* disabled last divider */
.ant-table .ant-table-thead > tr > th:nth-last-child(-n+2)::before {display:none;}

/* empty */
.ant-table-tbody > tr.ant-table-placeholder:hover > td {background:transparent;}

.ant-table-cell-scrollbar {box-shadow:none;}
.ant-table-pagination.ant-pagination {margin:8px 0;}
.ant-table-cell-fix-left, .ant-table-cell-fix-right {background:var(--bg-acc);}

/*--- ANT table [Dividing line for Drag and Drop] ---*/
.ant-table tr.method-builder-drop-over-upward {box-shadow:0 1px 0 var(--main-accent-color) inset;}
.ant-table tr.method-builder-drop-over-downward {box-shadow:0 -2px 0 var(--main-accent-color) inset;}

/*--- ANT pagination ---*/
.ant-pagination-item a {color:var(--main-tx);}
.ant-pagination-prev button, .ant-pagination-next button {color:var(--main-icon-color);}
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {color:var(--main-accent-color);}
.ant-pagination-item-active, .ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {background:var(--bg-acc); border:1px solid var(--main-accent-color-more_light);}
.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a,
.ant-pagination-item-active a, .ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {color:var(--main-accent-color);}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {color:var(--main-more-light); opacity:0.3;}
.ant-select.ant-pagination-options-size-changer {color:var(--contrast-tx);}
.ant-select.ant-pagination-options-size-changer .ant-select-selector {background:var(--bg-acc); border:1px solid var(--border-color-m1);}
.ant-select.ant-pagination-options-size-changer .ant-select-arrow {color:var(--main-icon-color);}

/*--- Login Form ---*/
.ant-form.login-form {}
.ant-form.login-form .ant-form-item {margin:12px 0;}
.ant-form.login-form .ant-form-item .ant-input-affix-wrapper {border-radius:0; border-width:0 0 1px 0 !important; box-shadow:none;}
.ant-form.login-form .ant-form-item .ant-input-affix-wrapper > input.ant-input {background:none; width:100%; height:28px; line-height:28px; padding:0 8px; outline:none;}
.ant-form.login-form .ant-input-affix-wrapper.not-empty > input.ant-input {font-size:14px; font-weight:bold;}
.ant-form.login-form .ant-form-item .ant-input-affix-wrapper > input.ant-input::placeholder {color:var(--placeholder-color); transition:var(--main-transition);}
.ant-form.login-form .ant-input-affix-wrapper:focus > input.ant-input::placeholder, .ant-form.login-form .ant-input-affix-wrapper-focused > input.ant-input::placeholder {text-indent:200px;}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix {width:20px; color:#a2a6aa; transition:color var(--main-transition);}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix > span {width:100%; display:flex; justify-content:center; align-items:center; cursor:help;}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix > span.domain-name svg {width:15px;}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix > span.password-field svg {width:11px;}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix > span.verification-code svg {width:19px;}
.ant-form.login-form .ant-input-affix-wrapper .ant-input-prefix > span.e-mail svg {width:17px;}
.ant-form.login-form .ant-input-affix-wrapper:focus .ant-input-prefix, .ant-form.login-form .ant-input-affix-wrapper-focused .ant-input-prefix {color:var(--main-accent-color);}
/* Field Validation */
.ant-form.login-form .ant-form-item.ant-form-item-has-error .ant-input-prefix {color:var(--red-accent-color);}
.ant-form.login-form .ant-form-item-explain.ant-form-item-explain-connected {padding:4px 0 0 2px;}
/* Field  Clear Button */
.ant-form.login-form .ant-input-clear-icon {opacity:0.2; transition:opacity var(--main-transition);}
.ant-form.login-form .ant-input-clear-icon:hover {opacity:0.4;}
.ant-form.login-form .anticon.anticon-close-circle svg {display:none;}
.ant-form.login-form .anticon.anticon-close-circle {width:12px; height:12px; display:inline-block; position:relative;}
.ant-form.login-form .anticon.anticon-close-circle:before,
.ant-form.login-form .anticon.anticon-close-circle:after {content:""; background:#000; width:1px; height:100%; position:absolute; border-radius:1px; display:inline-block;}
.ant-form.login-form .anticon.anticon-close-circle:before {transform:rotate(45deg);}
.ant-form.login-form .anticon.anticon-close-circle:after {transform:rotate(-45deg);}

/* Form Big Button */
.login-form-buttons-block {padding:46px 0 0 0;}
.login-form-buttons-block .ant-btn {background:#ffbe41; width:100%; height:32px; line-height:32px; font-size:19px; border:0; border-radius:var(--main-corner-radius); box-shadow:1px 1px 0 #e0aa45;}
.login-form-buttons-block .ant-btn:hover {background:#ffc556 !important;}
/* Form Big Button [back] */
.login-form-buttons-block .ant-btn.back-button {background:#f8f8f8; font-size:12px; text-transform:uppercase; box-shadow:none; margin:4px 0 0 0;}
.login-form-buttons-block .ant-btn.back-button:hover {background:#f4f4f4;}

/*--- Custom ScrollBar ---*/
.ScrollbarsCustom-Track {transition:.2s ease-in-out;}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {width:3px !important; height:calc(100% - 10px) !important; top:5px !important;}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {height:3px !important; width:calc(100% - 10px) !important; left:5px !important;}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY:hover, .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY:active {width:6px !important; right:-1px !important;}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX:hover, .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX:active {height:6px !important; bottom:-2px !important;}
.ScrollbarsCustom-Thumb {background:rgba(0,0,0, 0.2) !important;}

/*--- Loader ---*/
.custom-loader-spinner-type {position:relative; overflow:hidden;}
@keyframes loader-overlay {from {opacity:0;} to {opacity:0.8;}}
@keyframes spinner-emergence {from {opacity:0} to {opacity:1}}
@keyframes spinner-rotate {to {transform:rotate(360deg)}}
.custom-loader-spinner-type:before {content:""; background:#ffffff; position:absolute; top:0; bottom:0; left:0; right:0; opacity:0.8; animation:loader-overlay .3s ease-in-out; z-index:9999;}
.custom-loader-spinner-type:after {content:""; width:32px; height:32px; border:2px solid #03a000; border-right:2px solid transparent; border-radius:50%; position:absolute; top:50%; left:50%; margin:-16px 0 0 -16px; opacity:1; animation:spinner-rotate .73s linear infinite, spinner-emergence .3s ease-in-out; z-index:9999;}

/*--- Table (simple)---*/
.custom-table {width:100%; border-collapse:collapse;}
.custom-table tr {transition:background var(--main-transition);}
.custom-table tr:hover {background:var(--bg-acc);}
.custom-table th {font-weight:normal; line-height:18px; padding:4px 10px; background:var(--bg-acc); color:var(--contrast-tx2); border-left:1px solid #ececec; border-bottom:1px solid #ececec;}
.custom-table td {padding:4px 10px; border-top:1px solid #efefef; border-left:1px dashed #ececec;}
.custom-table th:first-child, .custom-table td:first-child {border-left:0;}
.custom-table tr:first-child td {border-top:0;}
.custom-table .accent-column {color:var(--contrast-tx2);}

/*--- Tooltip ---*/
.ant-tooltip-inner {background:var(--main-panels-bg); padding:8px 12px; color:var(--main-tx); box-shadow:0 3px 14px -4px rgba(0,0,0, 0.4);}
.ant-tooltip-arrow-content {background-color:var(--main-panels-bg); width:10px; height:10px;}
.ant-tooltip-arrow-content:before {display:none;}
/*--- Tooltip [for Login Page]---*/
.ant-tooltip.less-shadow {max-width:400px;}
.ant-tooltip.less-shadow .ant-tooltip-inner {box-shadow:2px 2px 7px -4px rgba(0,0,0, 0.2);}