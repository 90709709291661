.search_block {width:100%; min-height:34px; display:flex; justify-content:center; margin:0 0 16px 0;}
.search_field {width:100%; max-width:700px; display:flex; padding:0 14px; position:relative;}
@media (max-width:700px) {.search_field {max-width:100%;}}
.search_field_inner {width:100%; position:relative; overflow:hidden;}

.search_field_inner input {background:transparent; width:100%; height:34px; font-size:16px; font-weight:bold; border:2px solid var(--main-icon-color); padding:0 42px 0 10px; color:var(--contrast-tx); border-radius:6px; transition:border var(--main-transition);}
.search_field_inner input::-webkit-input-placeholder {font-weight:normal; letter-spacing:normal; text-overflow:ellipsis; color:var(--main-more-light); transition:var(--main-transition);}
.search_field_inner input::-moz-placeholder {font-weight:normal; letter-spacing:normal; text-overflow:ellipsis; color:var(--main-more-light); transition:var(--main-transition);}
.search_field_inner input::-ms-input-placeholder {font-weight:normal; letter-spacing:normal; text-overflow:ellipsis; color:var(--main-more-light); transition:var(--main-transition);}
.search_field_inner input:focus::-webkit-input-placeholder {color:transparent; text-indent:-1000px;}
.search_field_inner input:focus::-moz-placeholder {color:transparent; text-indent:-1000px;}
.search_field_inner input:focus::-ms-input-placeholder {color:transparent; text-indent:-1000px;}
.search_field_inner input:hover, .search_field_inner input:focus {border:2px solid var(--main-accent-color);}

.search_field_inner button {background:transparent; border:0; width:34px; min-width:34px; height:30px; position:absolute; top:2px; right:2px; transition:var(--main-transition); outline:none; cursor:pointer; border-radius:0 6px 6px 0; color:var(--main-icon-color);}
.search_field_inner button:hover {background:var(--bg-acc); color:var(--main-accent-color);}
.search_field_inner button svg {height:17px; position:relative; top:3px; right:-1px;}

.search_field_inner .clear_btn {color:var(--main-icon-color); z-index:0; opacity:0; top:-32px; right:34px; cursor:pointer;}
.search_field_inner .clear_btn:after {content:""; width:1px; height:0; background:var(--border-color-m1); transition:var(--main-transition) 0.2s;}
.search_field_inner .clear_btn:hover {background:var(--bg-acc); color:var(--main-accent-color);}
.search_field_inner .clear_btn svg {height:12px; top:2px;}

.search_field_inner.search_process button.clear_btn {opacity:1; z-index:1; top:2px;}
.search_field_inner.search_process .clear_btn:after {height:20px; position:absolute; right:-1px;}

/* Show Own button */
.show_own_link {height:22px; background:none; white-space:nowrap; border:none; outline:none; cursor:pointer; padding:0 2px; margin:4px 0 0 16px; border-bottom:1px solid; color:var(--main-accent-color); transition:border var(--main-transition);}
.show_own_link:hover {border-bottom:1px solid transparent;}

/* country/language selectors */
.country_language_selectors {width:100%; background:var(--bg-acc); box-shadow:1px 1px 0 rgba(0,0,0, 0.02) inset; display:flex; justify-content:center; padding:16px 0; margin:0 0 16px 0;}
.country_language_selectors_inner {width:674px; display:flex; gap:0 8px;}
@media (max-width:720px) {.country_language_selectors_inner {margin:0 14px;}}
.country_language_selectors_inner > div {min-width:30%; flex:auto;}