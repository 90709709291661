.name_block {}
.material_name {font-size:14px; font-weight:bold; text-align:left;}
button.material_name {background:none; color:var(--main-accent-color-more-dark); cursor:pointer; outline:none; border:0; border-bottom:1px solid transparent; transition:var(--main-transition);}
button.material_name:hover {border-bottom:1px solid;}
p.material_name {color:var(--contrast-tx);} 
.vendor_name {font-weight:bold; color:var(--contrast-tx2);}

.aditional_info {}
.divider {border-left:1px solid var(--border-color-m2); margin: 0 8px 0 9px;}

.pdf_wrapper, .pdf_wrapper > div {height:100%;}